export const siteMapData = ({ t }) => [
  {
    name: 'FINSO S.p.A.',
    office1:
      `<p>Sede Legale e Sede Operativa: Via G. del Pian dei Carpini 1 - 50127 Firenze - Italy</p>`,
    register: '',
    legal: `
    <p>Registro delle Imprese di Firenze<br />
    C.F. e n. iscr. 15952111001<br />
    P.IVA 15952111001<br />
    N. R.E.A.  FI - 679859<br />
    Capitale Sociale i.v. € 20.000.000,00</p>`,
    soc: `Società soggetta a direzione e coordinamento di Fincantieri Infrastructure S.p.A.`,
    contacts: `
    CONTATTI<br />
    E-Mail: <a href="mailto:info@finso.it">info@finso.it</a><br />
    Pec: <a href="mailto:fincantieriinfrastrutturesociali@legalmail.it">fincantieriinfrastrutturesociali@legalmail.it</a><br />
    Sito: <a href="https://www.finso.it">www.finso.it</a>
    `,
  },
  {

  },

];
