export const menuMobileData = ({ t }) => {
  return [
    {
      exact: false,
      to: { pathname: process.env.REACT_APP_GROUP_URL },
      labelLink: t('menu.group'),
      target: '_parent',
    },
    {
      exact: true,
      to: '/',
      labelLink: 'Home',
    },
    {
      exact: false,
      to: '/profile',
      labelLink: t('menu.profile'),
      subMenu: [
        { title: t('profile.who-we-are'), linkTo: '/profile/who-we-are' },
        {
          title: t('profile.our-history'),
          linkTo: '/profile/our-history',
        },
        {
          title: t('profile.business-areas'),
          linkTo: '/profile/business-areas',
        },
        {
          title: t('profile.footprint'),
          linkTo: '/profile/footprint',
        },
      ],
    },
    {
      exact: false,
      to: '/projects',
      labelLink: t('menu.projects'),
    },
    {
      exact: false,
      to: '/governance',
      labelLink: t('menu.governance'),
      subMenu: [
        {
          title: t('governance.management'),
          linkTo: '/governance/management',
        },
        {
          title: t('governance.ethics'),
          linkTo: '/governance/ethics',
        },
        {
          title: t('governance.whistle-blowing'),
          linkTo: '/governance/whistle-blowing',
        },
      ],
    },
    {
      exact: false,
      to: '/sustainability',
      labelLink: t('menu.sustainability'),
      subMenu: [
        {
          title: t('sustainability.commitment'),
          linkTo: '/sustainability/commitment',
        },
        {
          title: t('sustainability.certifications'),
          linkTo: '/sustainability/certifications',
        },
        {
          title: t('sustainability.sa-8000-reports'),
          linkTo: '/sustainability/sa-8000-reports',
        },
      ],
    },
    {
      exact: false,
      to: '/contacts',
      labelLink: t('menu.contacts'),
    },
    {
      exact: false,
      to: '/work-with-us',
      labelLink: t('menu.work-with-us'),
    },
    {
      exact: false,
      to: '/suppliers',
      labelLink: t('menu.suppliers'),
    },
    {
      exact: false,
      to: '/download',
      labelLink: t('menu.download'),
    },
  ];
};
