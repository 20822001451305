import { lazy } from 'react';

import { NoMatch } from 'shared';
import { Redirect } from 'react-router-dom';
const Home = lazy(() => import('../pages/Home'));
const Profile = lazy(() => import('../pages/Profile'));
const WhoWeAre = lazy(() => import('../pages/WhoWeAre'));
const OurHistory = lazy(() => import('../pages/OurHistory'));
const BusinessAreas = lazy(() => import('../pages/BusinessAreas'));
const HealthcareInfrastructure = lazy(() => import('../pages/HealthcareInfrastructure'));
const MedicalTechnologies = lazy(() => import('../pages/MedicalTechnologies'));
const Buildings = lazy(() => import('../pages/Buildings'));
const Partnership = lazy(() => import('../pages/Partnership'));
const Footprint = lazy(() => import('../pages/Footprint'));
const Projects = lazy(() => import('../pages/Projects'));
const Case = lazy(() => import('../pages/Case'));
const Governance = lazy(() => import('../pages/Governance'));
const Management = lazy(() => import('../pages/Management'));
const Ethics = lazy(() => import('../pages/Ethics'));
const WhistleBlowing = lazy(() => import('../pages/WhistleBlowing'));
const Sustainability = lazy(() => import('../pages/Sustainability'));
const Commitment = lazy(() => import('../pages/Commitment'));
const Certifications = lazy(() => import('../pages/Certifications'));
const SA8000Report = lazy(() => import('../pages/SA8000Report'));
const Contacts = lazy(() => import('../pages/Contacts'));
const WorkWithUs = lazy(() => import('../pages/WorkWithUs'));
const Suppliers = lazy(() => import('../pages/Suppliers'));
const Download = lazy(() => import('../pages/Download'));
const Privacy = lazy(() => import('../pages/Privacy'));
const Credits = lazy(() => import('../pages/Credits'));

export const routes = [
  { exact: true, path: '/', component: <Home /> },
  { exact: true, path: '/index.html', component: <Home /> },

  {
    exact: true,
    path: '/profile',
    component: <Redirect to="profile/who-we-are" />,
  },
  { exact: true, path: '/profile/who-we-are', component: <WhoWeAre /> },
  { exact: true, path: '/profile/our-history', component: <OurHistory /> },
  { exact: true, path: '/profile/business-areas', component: <BusinessAreas /> },
  { exact: true, path: '/profile/business-areas/healthcare-infrastructure', component: <HealthcareInfrastructure /> },
  { exact: true, path: '/profile/business-areas/medical-technologies', component: <MedicalTechnologies /> },
  { exact: true, path: '/profile/business-areas/buildings', component: <Buildings /> },
  { exact: true, path: '/profile/business-areas/partnership', component: <Partnership /> },
  { exact: true, path: '/profile/footprint', component: <Footprint /> },

  { exact: true, path: '/projects', component: <Projects /> },
  { exact: true, path: '/projects/:id', component: <Case /> },
  {
    exact: true,
    path: '/governance',
    component: <Redirect to="governance/management" />,
  },
  { exact: true, path: '/governance/management', component: <Management /> },
  { exact: true, path: '/governance/ethics', component: <Ethics /> },
  {
    exact: true,
    path: '/governance/whistle-blowing',
    component: <WhistleBlowing />,
  },
  {
    exact: true,
    path: '/sustainability',
    component: <Redirect to="sustainability/commitment" />,
  },
  {
    exact: true,
    path: '/sustainability/commitment',
    component: <Commitment />,
  },
  {
    exact: true,
    path: '/sustainability/certifications',
    component: <Certifications />,
  },
  {
    exact: true,
    path: '/sustainability/sa-8000-reports',
    component: <SA8000Report />,
  },

  { exact: true, path: '/contacts', component: <Contacts /> },

  { exact: true, path: '/work-with-us', component: <WorkWithUs /> },
  { exact: true, path: '/suppliers', component: <Suppliers /> },
  { exact: true, path: '/download', component: <Download /> },
  { exact: true, path: '/privacy', component: <Privacy /> },
  { exact: true, path: '/credits', component: <Credits /> },

  { exact: false, path: '*', component: <NoMatch /> },
];
